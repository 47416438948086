<template>
  <div>
    <div style="display:table;width:100%">
      <div class="left">
        <div
          v-for="(item,index) in tickets"
          :key="index"
          :class="active==index?'selected':'navbar'"
          @click="tabChange(index)"
        >{{item.name}}</div>
        <div
          :class="active=='-1'?'selected':'navbar'"
          @click="tabChange('-1')"
          style="color:darkorange"
        >添加 +</div>
      </div>
      <div style="padding:10px 0 10px 30px;display: table-cell">
        <div v-if="active>-1">
          <!-- 门票详情 -->
          <div class="ticketnamebox">
            {{ticket.name}}
            <span style="margin-left:40px;font-size:initial">
              <span v-if="ticket.isShow" style="color:mediumseagreen">已上架</span>
              <span v-else style="color:indianred">已下架</span>
            </span>
          </div>
          <el-row :gutter="60" v-if="active != -1">
            <el-col :span="12" v-if="active>-1">
              <table>
                <tr>
                  <td width="100px">
                    <span class="title">操作</span>
                  </td>
                  <td>
                    <span class="editstyle" @click="editTicket">编辑名称和说明</span>
                    <el-dropdown
                      style="margin:0 40px"
                      class="editstyle"
                      @command="handleCommand"
                      trigger="click"
                    >
                      <span>
                        浮动价格
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">标准价</el-dropdown-item>
                        <el-dropdown-item command="2">周末价</el-dropdown-item>
                        <el-dropdown-item command="3">特定价</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>

                    <el-dropdown
                      style="margin-right:40px"
                      class="editstyle"
                      @command="setTicketShowFn"
                      trigger="click"
                    >
                      <span>
                        <span v-if="ticket.isShow" style="color:mediumseagreen">
                          已上架
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <span v-else style="color:indianred">
                          已下架
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1" :disabled="ticket.isShow==1">上架</el-dropdown-item>
                        <el-dropdown-item command="2" :disabled="ticket.isShow==0">下架</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>

                    <span v-if="ticket.type==1" class="deletestyle" @click="deleteTicketConfirm">删除</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="title">门票说明</span>
                  </td>
                  <td>{{ticket.intro}}</td>
                </tr>
              </table>
              <div>
                <div class="title" style="margin-bottom:20px">价格规则</div>
                <el-table :data="ticket.newRules" style="width: 100%">
                  <el-table-column prop="name" label="名称"></el-table-column>
                  <el-table-column prop="price" label="价格"></el-table-column>
                  <el-table-column prop="startDate" label="开始"></el-table-column>
                  <el-table-column prop="endDate" label="结束"></el-table-column>
                  <el-table-column label="编辑价格">
                    <template slot-scope="scope">
                      <i
                        class="el-icon-edit-outline"
                        style="color:#36a2ef"
                        @click="openPriceBox(scope.row)"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column label="删除">
                    <template slot-scope="scope">
                      <i v-if="scope.row.basic==1" class="el-icon-lock"></i>
                      <i
                        v-else
                        class="el-icon-delete"
                        style="color:red"
                        @click="deletePriceRuleConfirm(scope.row)"
                      ></i>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :span="12" v-if="active > -1">
              <div class="title" style="margin-bottom: 30px;text-align:right">
                <span style="margin-right:80px">日期价格表</span>
                <el-select
                  v-model="monthSelected"
                  placeholder="请选择"
                  size="small"
                  @change="onMonthChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div
                style="padding:20rpx 0; background: #fff; min-height:400px"
                v-loading="loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              >
                <div class="pricebox">
                  <div
                    v-for="(item,index) in weekName"
                    :key="index"
                    class="priceitem"
                    style="font-weight:800;color:#777"
                  >{{item}}</div>
                </div>
                <div v-for="(data,index) in ticketMonthPrice" :key="index+'m'" class="pricebox">
                  <div v-for="(element,index) in data" :key="index+'n'" class="priceitem">
                    <div :class="element.day==-1?'white':'datebox'">
                      <div class="datefirst">
                        <div v-if="element.date==today" style="color:darkorange">今日</div>
                        <div v-else>{{element.day}}</div>
                        <div v-if="element.level==3" style="color:darkorange">特定</div>
                        <div v-if="element.level==2" style="color:#37b4ed">周末</div>
                      </div>
                      <div :class="element.day==-1?'white':'pricewrap'">
                        <div>{{element.price}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <div class="ticketnamebox">添加新收费项目</div>
          <div style="width:40%;margin:0px auto">
            <el-form :model="ticketEdit" label-width="80px">
              <el-form-item label="名称">
                <el-input v-model="ticketEdit.name" maxlength="6" placeholder="请输入名称"></el-input>
              </el-form-item>
              <el-form-item label="价格">
                <el-input v-model="ticketEdit.price" placeholder="请输入价格" type="number"></el-input>
              </el-form-item>
              <el-form-item label="说明">
                <el-input v-model="ticketEdit.intro" type="textarea" autosize placeholder="请输入购买及使用说明"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="addTicketFn">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改名称和说明 -->
    <el-dialog :visible.sync="editShow" title="修改名称和说明" :center="true">
      <div style="width:50%;margin:20px auto">
        <el-form :model="ticketEdit" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="ticketEdit.name"></el-input>
          </el-form-item>
          <el-form-item label="说明">
            <el-input v-model="ticketEdit.intro" type="textarea" autosize></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="saveTicketEdit">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 添加时段标准价 -->
    <el-dialog :visible.sync="showAddRuleLevel" title="添加时段标准价" :center="true">
      <div style="width:50%;margin:20px auto">
        <el-form label-width="80px">
          <el-form-item label="起始日期">
            <el-date-picker
              append-to-body
              format="MM-dd"
              value-format="yyyy-MM-dd"
              v-model="rule.start"
              type="date"
              :picker-options="pickerOptions"
              :editable="false"
              placeholder="选择日期"
              style="width:200px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-input placeholder="12-31" disabled style="width:200px"></el-input>
          </el-form-item>
          <el-form-item label="价格">
            <el-input v-model="rule.price" type="number" style="width:200px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="saveRule">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 添加周末和特定价 -->
    <el-dialog :visible.sync="showAddRule" :title="titleRule" :center="true">
      <div style="width:70%;margin:20px auto">
        <el-form label-width="80px">
          <el-form-item label="起始日期">
            <el-date-picker
              append-to-body
              format="MM-dd"
              value-format="yyyy-MM-dd"
              v-model="dateValue"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width:400px"
              @change="datePickChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="价格">
            <el-input v-model="rule.price" type="number" style="width:400px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="saveRule">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showPriceEdit" :title="titleRule" :center="true">
      <div style="width:40%;margin:20px auto">
        <el-form label-width="80px">
          <el-form-item label="价格">
            <el-input v-model="ruleEdit.price" type="number"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="savePrice">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTicketsPc,
  getTicketMonthPricePc,
  updateTicketPc,
  deleteTicketPc,
  addTicketPc,
  deletePriceRulePc,
  addPriceRulePc,
  setTicketShowPc,
  saveRulePricePc
} from "@/api/api";
export default {
  data() {
    return {
      loading: false,
      tickets: [],
      active: 0,
      editShow: false,
      dateValue: [],

      titleRule: "",
      ticket: {},
      ticketEdit: {},
      ruleEdit: {},
      ticketMonthPrice: [],
      optionStart: new Date().setDate(new Date().getDate() - 1),
      optionEnd: this.formateTime(new Date().getFullYear() + "-12-31"),
      showAddRuleLevel: false,
      showAddRule: false,
      showPriceEdit: false,
      rule: {
        start: "",
        end: "",
        price: "",
        level: "",
        idTicket: ""
      },
      options: [
        {
          text: "1月",
          value: 1
        },
        {
          text: "2月",
          value: 2
        },
        {
          text: "3月",
          value: 3
        },
        {
          text: "4月",
          value: 4
        },
        {
          text: "5月",
          value: 5
        },
        {
          text: "6月",
          value: 6
        },
        {
          text: "7月",
          value: 7
        },
        {
          text: "8月",
          value: 8
        },
        {
          text: "9月",
          value: 9
        },
        {
          text: "10月",
          value: 10
        },
        {
          text: "11月",
          value: 11
        },
        {
          text: "12月",
          value: 12
        }
      ],
      today: "",
      monthSelected: "",
      weekName: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
    };
  },
  mounted() {
    this.getTicketsPcFn();
    this.monthSelected = new Date().getMonth() + 1;
  },
  computed: {
    pickerOptions() {
      let start = this.optionStart;
      let end = this.optionEnd;
      return {
        disabledDate(time) {
          return (time.getTime() < start) | (time.getTime() > end);
        }
      };
    },
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    // 获取所有门票
    getTicketsPcFn() {
      const that = this;
      getTicketsPc().then(res => {
        if (res.result) {
          that.tickets = res.data.tickets;
          that.ticket = res.data.tickets[this.active];
          that.isTicketSell = res.data.isTicketSell;
          that.getTicketMonthPricePcFn();
        }
      });
    },
    //标签切换
    tabChange(index) {
      if (index == this.active) {
        return;
      } else if (index > -1) {
        this.ticket = this.tickets[index];
        this.getTicketMonthPricePcFn();
      } else {
        this.ticketEdit = {
          name: "",
          price: "",
          intro: ""
        };
      }
      this.active = index;
    },
    //获取月价格
    getTicketMonthPricePcFn() {
      const that = this;
      that.loading = true;
      getTicketMonthPricePc({
        id: that.ticket.id,
        month: that.monthSelected
      }).then(res => {
        if (res.result) {
          that.setTicketRows(res.data.prices);
          that.today = res.data.today;
        }
        that.loading = false;
      });
    },
    //设置日期价格
    setTicketRows(ticketPrice) {
      let week = ticketPrice[0].week;
      if (week == 0) {
        week = 7;
      }
      let num = week - 1;
      for (let i = 0; i < num; i++) {
        let rsp = {
          day: -1,
          price: -2
        };
        ticketPrice.unshift(rsp);
      }
      let dateRows = [];
      for (let i = 0; i < 6; i++) {
        let start = i * 7;
        let end = start + 7;
        let rsp = ticketPrice.slice(start, end);
        dateRows.push(rsp);
      }
      this.ticketMonthPrice = dateRows;
    },
    //选择月份
    onMonthChange() {
      this.getTicketMonthPricePcFn();
    },
    // 打开编辑弹框
    editTicket() {
      this.ticketEdit = { ...this.ticket };
      this.editShow = true;
    },

    // 编辑名称和介绍
    saveTicketEdit() {
      const that = this;
      updateTicketPc(that.ticketEdit).then(res => {
        if (res.result) {
          that.ticket = that.ticketEdit;
          let index = parseInt(that.active);
          that.tickets[index] = that.ticketEdit;
          that.ticketEdit = {};
          that.editShow = false;
        }
      });
    },
    //上下架
    setTicketShowFn() {
      if (!this.isTicketSell) {
        this.$alert(
          "请先再景区管理中开通网络售票,开通网络售票后才能上下架商品",
          "网络售票未开通",
          {
            type: "warning",
            center: true,
            callBack: () => {}
          }
        );
        return;
      }
      let value = !this.ticket.isShow;
      if (!value) {
        let isShowTickets = this.tickets.filter(item => {
          return item.isShow == 1;
        });
        if (isShowTickets.length == 1) {
          this.$message("不能下架所有门票项目");
          return;
        }
      }
      const that = this;
      setTicketShowPc({
        id: that.ticket.id,
        value: value
      }).then(res => {
        if (res.result) {
          that.ticket.isShow = value;
          let index = that.tickets.findIndex(item => {
            return item.id == that.ticket.id;
          });
          that.tickets[index].isShow = value;
        }
      });
    },
    //删除门票确认
    deleteTicketConfirm() {
      const that = this;
      this.$confirm("确认删除" + this.ticket.name + "？", {
        type: "warning"
      })
        .then(() => {
          that.deleteTicketPcFn();
        })
        .catch(() => {});
    },
    // 删除门票
    deleteTicketPcFn() {
      const that = this;
      deleteTicketPc({
        id: that.ticket.id
      }).then(res => {
        if (res.result) {
          that.active = 0;
          that.getTicketsPcFn();
        }
      });
    },
    //添加门票
    addTicketFn() {
      const that = this;
      addTicketPc(that.ticketEdit).then(res => {
        if (res.result) {
          this.ticketEdit = {
            name: "",
            price: "",
            intro: ""
          };
          this.$message.success("添加成功");
          that.active = that.tickets.length;
          that.getTicketsPcFn();
        }
      });
    },

    // 新增价格选择
    handleCommand(index) {
      this.rule.idTicket = this.ticket.id;
      this.rule.level = index;
      if (index == 1) {
        let rules = this.ticket.newRules.filter(item => {
          return item.level == 1;
        });
        let index = rules.length - 1;
        this.optionStart =
          this.formateTime(rules[index].start) + 15 * 24 * 60 * 60 * 1000;
        this.optionEnd = this.formateTime(new Date().getFullYear() + "-12-16");
        this.rule.end = new Date().getFullYear() + "-12-31";
        this.showAddRuleLevel = true;
      } else if (index == 2) {
        this.titleRule = "添加周末价";
        this.dateValue = [];
        this.showAddRule = true;
      } else {
        this.titleRule = "添加特定价";
        this.dateValue = [];
        this.showAddRule = true;
      }
    },

    //周末和特定价的日期覆盖检查
    datePickChange() {
      const that = this;
      let name = "特定价";
      if (that.rule.level == 2) {
        name = "周末价";
      }
      let rules = that.ticket.rules.filter(item => {
        return item.level == that.rule.level;
      });

      let newRuleStart = that.formateTime(that.dateValue[0]);
      let newRuleEnd = that.formateTime(that.dateValue[1]);

      rules.forEach(element => {
        let itemStart = that.formateTime(element.start);
        let itemEnd = that.formateTime(element.end);
        if (
          (newRuleStart >= itemStart && newRuleStart <= itemEnd) ||
          (newRuleEnd >= itemStart && newRuleEnd <= itemEnd)
        ) {
          that.dateValue = [];
          that.$alert(
            "与现有" + name + "的时间范围冲突，请重新选择日期范围",
            "有效期冲突",
            {
              type: "warning",
              callBack: () => {}
            }
          );
          return;
        }
      });
      that.rule.start = that.dateValue[0];
      that.rule.end = that.dateValue[1];
    },

    // 保存新增价格规则
    saveRule() {
      const that = this;
      if (!this.rule.start || !that.rule.end) {
        this.$alert("请选择日期", {
          type: "warning",
          callBack: () => {}
        });
      } else if (!this.rule.price) {
        this.$alert("请输入价格", {
          type: "warning",
          callBack: () => {}
        });
      } else {
        addPriceRulePc(that.rule).then(res => {
          if (res.result) {
            that.getTicketsPcFn();
            that.showAddRuleLevel = false;
            that.showAddRule = false;
            that.rule = {
              start: "",
              end: "",
              price: "",
              level: "",
              idTicket: ""
            };
          }
        });
      }
    },

    // 删除价格确认
    deletePriceRuleConfirm(rule) {
      const that = this;
      this.$confirm("确认删除？", {
        type: "warning"
      })
        .then(() => {
          that.deleteRuleFn(rule);
        })
        .catch(() => {});
    },

    //删除价格
    deleteRuleFn(rule) {
      const that = this;
      deletePriceRulePc({
        id: rule.id,
        level: rule.level
      }).then(res => {
        if (res.result) {
          that.getTicketsPcFn();
        }
      });
    },

    openPriceBox(rule) {
      this.ruleEdit = { ...rule };
      this.titleRule = "修改标准价的价格";
      if (rule.level == 2) {
        this.titleRule = "修改周末价的价格";
      } else if (rule.level == 3) {
        this.titleRule = "修改特定价的价格";
      }
      this.showPriceEdit = true;
    },

    //保存价格
    savePrice() {
      const that = this;
      saveRulePricePc({
        id: that.ruleEdit.id,
        price: that.ruleEdit.price
      }).then(res => {
        if (res.result) {
          that.getTicketsPcFn();
          that.showPriceEdit = false;
          that.ruleEdit = {};
        }
      });
    }
  }
};
</script>

<style scoped>
.title {
  font-weight: bold;
}
.navbar {
  padding: 10px 5px;
  text-align: right;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.selected {
  padding: 10px 5px;
  text-align: right;
  background: #06375a;
  color: #fff;
  cursor: pointer;
}
.ticketnamebox {
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 18px;
  border-bottom: 1px dashed #aaa;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table td {
  vertical-align: top;
  padding-bottom: 40px;
}

.pricebox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2px;
}

.priceitem {
  margin: 20rpx 0rpx;
  text-align: center;
  width: 14%;
  color: #777;
}

.datebox {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 2px;
}

.datefirst {
  margin-bottom: 15px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricewrap {
  font-size: 16px;
  color: initial;
}

.white {
  background: #fff;
  color: #fff;
  border: none;
}

.editstyle {
  color: #36a2ef;
  cursor: pointer;
}
.deletestyle {
  color: red;
  cursor: pointer;
}

.left {
  display: table-cell;
  border-right: 1px solid #777;
  width: 8%;
}
.right {
  padding: 10px 0 10px 30px;
  display: table-cell;
}
</style>

